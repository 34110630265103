<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible" :before-close="handleClose">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="dataForm.type" @change="reset">
          <el-radio v-for="(type, index) in dataForm.typeList" :label="index" :key="index">{{ type }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="dataForm.typeList[dataForm.type] + '名称'" prop="name">
        <el-input v-model="dataForm.name" :placeholder="dataForm.typeList[dataForm.type] + '名称'" maxLength="10">
        </el-input>
      </el-form-item>
      <el-form-item label="上级菜单" prop="parentName">
        <el-popover ref="menuListPopover" placement="bottom-start" trigger="click">
			<el-scrollbar style="height:100%">
				<div class="scroll">
					<el-tree :data="menuList" :props="menuListTreeProps" node-key="id" ref="menuListTree" @current-change="menuListTreeCurrentChangeHandle" :default-expand-all="true" :highlight-current="true" :expand-on-click-node="false">
					</el-tree>
				</div>
			</el-scrollbar>
        </el-popover>
        <el-input v-model="dataForm.parentName" v-popover:menuListPopover :readonly="true" placeholder="点击选择上级菜单" class="menu-list__input"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.type === 1" label="菜单路由" prop="url">
        <el-input v-model="dataForm.url" placeholder="菜单路由"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.type === 1" label="组件地址" >
        <el-input v-model="dataForm.location" placeholder="组件地址"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 0" label="授权标识" prop="permissions">
        <el-input v-model="dataForm.permissions" placeholder="多个用逗号分隔, 如: user:list,user:create"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 2" label="排序号" prop="sort">
        <el-input-number v-model="dataForm.sort" controls-position="right" :min="0" label="排序号">
        </el-input-number>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 2" label="菜单图标" prop="icon">
        <el-row>
          <el-col :span="22">

            <el-input v-model="dataForm.icon" placeholder="菜单图标名称" class="icon-list__input"></el-input>
          </el-col>

        </el-row>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" :loading="btnloading" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    var validateUrl = (rule, value, callback) => {
      if (this.dataForm.type === 1 && !/\S/.test(value)) {
        callback(new Error("菜单URL不能为空"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataForm: {
        id: 0,
        type: 1,
        typeList: ["目录", "菜单", "按钮"],
        name: "",
        pid: 0,
        parentName: "",
        url: "",
        permissions: "",
        sort: 0,
        icon: "",
        iconList: [],
        location:''
      },
      dataRule: {
        name: [
          {
            required: true,
            message: "菜单名称不能为空",
            trigger: "blur",
          },
        ],
        // parentName: [{
        // 	required: true,
        // 	message: '上级菜单不能为空',
        // 	trigger: 'change'
        // }],
        url: [
          {
            validator: validateUrl,
            trigger: "blur",
          },
        ],
      },
      menuList: [],
      menuListTreeProps: {
        label: "name",
        children: "children",
      },
      btnloading: false,
    };
  },
  created() {},
  methods: {
    reset(val) {
      this.dataForm = {
        id: 0,
        type: val,
        typeList: ["目录", "菜单", "按钮"],
        name: "",
        pid: 0,
        parentName: "",
        url: "",
        permissions: "",
        sort: 0,
        icon: "",
        location:'',
        iconList: [],
      };
    },
    init(id) {
      this.visible = true;
      this.dataForm.id = id || 0;
      this.$get("/platform-admin/menu/select")
        .then((data) => {
          this.menuList = data.data;
        })
        .then(() => {
          this.visible = true;
          this.$nextTick(() => {
            this.$refs["dataForm"].resetFields();
          });
        })
        .then(() => {
          if (!this.dataForm.id) {
            // 新增
            this.menuListTreeSetCurrentNode();
          } else {
            // 修改
            this.$get(`/platform-admin/menu/${this.dataForm.id}`).then(
              (res) => {
                this.dataForm.id = res.data.id;
                this.dataForm.type = res.data.type;
                this.dataForm.name = res.data.name;
                this.dataForm.pid = res.data.pid;
                this.dataForm.url = res.data.url;
                this.dataForm.permissions = res.data.permissions;
                this.dataForm.sort = res.data.sort;
                this.dataForm.icon = res.data.icon;
                this.dataForm.location = res.data.location || '';
                this.menuListTreeSetCurrentNode();
              }
            );
          }
        });
    },
    // 关闭弹窗
    handleClose() {
      this.dataForm.pid = null;
      this.dataForm.parentName = null;
      this.$refs.dataForm.resetFields();
      this.visible = false;
    },
    // 菜单树选中
    menuListTreeCurrentChangeHandle(data, node) {
      this.dataForm.pid = data.id;
      this.dataForm.parentName = data.name;
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      if (this.dataForm.pid && this.dataForm.pid != 0) {
        this.$refs.menuListTree.setCurrentKey(this.dataForm.pid);
        this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() ||
          {})["name"];
      }
    },
    // 图标选中
    iconActiveHandle(iconName) {
      this.dataForm.icon = iconName;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.btnloading = true;
          let param = {
            id: this.dataForm.id || undefined,
            type: this.dataForm.type,
            name: this.dataForm.name,
            pid: this.dataForm.pid,
            url: this.dataForm.url,
            permissions: this.dataForm.permissions,
            sort: this.dataForm.sort,
            icon: this.dataForm.icon,
            parentName: this.dataForm.parentName,
            location: this.dataForm.location,
          };
          let res;
          if (this.dataForm.id) {
            res = await this.$put("/platform-admin/menu", param);
          } else {
            res = await this.$post("/platform-admin/menu", param);
          }
          this.btnloading = false;
          if (res && res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.handleClose();
                this.$emit("refreshDataList");
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mod-menu {
  .menu-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }

  &__icon-popover {
    width: 458px;
    overflow: hidden;
  }

  &__icon-inner {
    width: 478px;
    max-height: 258px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__icon-list {
    width: 458px;
    padding: 0;
    margin: -8px 0 0 -8px;

    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;

      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }

  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
.scroll{
	max-height: 400px;
}
</style>
