<template>
	<div class="mod-menu">
		<el-form :inline="true" :model="dataForm">
			<el-form-item>
				<el-button v-if="isAuth('sys:menu:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="dataList" row-key="id" border style="width: 100%; ">
			<el-table-column prop="name" header-align="center" width="300px" min-width="150" label="名称">
			</el-table-column>
			<el-table-column prop="parentName" header-align="center" align="center" width="120" label="上级菜单">
			</el-table-column>
			<el-table-column prop="type" width="80"  header-align="center" align="center" label="类型">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.type === 0" size="small">目录</el-tag>
					<el-tag v-else-if="scope.row.type === 1" size="small" type="success">菜单</el-tag>
					<el-tag v-else-if="scope.row.type === 2" size="small" type="info">按钮</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="sort" width="100"  header-align="center" align="center" label="排序号">
			</el-table-column>
			<el-table-column prop="url" header-align="center" align="center" width="150" :show-overflow-tooltip="true"
				label="菜单URL">
			</el-table-column>
			<el-table-column header-align="center" prop="location"  align="center" label="组件路径">
				
			</el-table-column>
			<el-table-column prop="permissions" header-align="center" align="center"  :show-overflow-tooltip="true"
				label="授权标识">
			</el-table-column>
			<el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-button v-if="isAuth('sys:menu:update')" type="text" size="small"
						@click="addOrUpdateHandle(scope.row.id)">修改</el-button>
					<el-button v-if="isAuth('sys:menu:delete')" class="c-danger-imp" type="text" size="small"
						@click="deleteHandle(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 弹窗, 新增 / 修改 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>

<script>
	import AddOrUpdate from './menu-add-or-update'
	import {
		treeDataTranslate
	} from '@/utils'
	export default {
		data() {
			return {
				dataForm: {},
				dataList: [],
				dataListLoading: false,
				addOrUpdateVisible: false
			}
		},
		components: {
			AddOrUpdate
		},
		
		created() {
			this.getDataList()
		},
		methods: {
			// 获取数据列表
			async getDataList() {
				this.dataListLoading = true
				// this.$http({
				// 	url: this.$http.adornUrl('/sys/menu/list'),
				// 	method: 'get',
				// 	params: this.$http.adornParams()
				// }).then(({
				// 	data
				// }) => {
				// 	this.dataList = treeDataTranslate(data, 'menuId')
				// 	this.dataListLoading = false
				// })
				let res=await this.$get('/platform-admin/menu/list');
				this.dataListLoading = false
				if(res&&res.code==0){
					this.dataList = res.data
				}
			},
			// 新增 / 修改
			addOrUpdateHandle(id) {
				this.addOrUpdateVisible = true;
				this.$nextTick(() => {
					this.$refs.addOrUpdate.init(id)
				})
			},
			// 删除
			deleteHandle(id) {
				this.$confirm(`确定执行删除操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$del(`/platform-admin/menu/${id}`).then((res) => {
						if (res && res.code === 0) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						} else {
							this.$message.error(res.msg)
						}
					})
				}).catch(() => {})
			}
		}
	}
</script>
